// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboard-2021-js": () => import("./../../../src/pages/leaderboard2021.js" /* webpackChunkName: "component---src-pages-leaderboard-2021-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-school-js": () => import("./../../../src/pages/school.js" /* webpackChunkName: "component---src-pages-school-js" */),
  "component---src-pages-student-js": () => import("./../../../src/pages/student.js" /* webpackChunkName: "component---src-pages-student-js" */),
  "component---src-pages-teacher-js": () => import("./../../../src/pages/teacher.js" /* webpackChunkName: "component---src-pages-teacher-js" */),
  "component---src-pages-technical-support-js": () => import("./../../../src/pages/technical-support.js" /* webpackChunkName: "component---src-pages-technical-support-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-torneo-2020-js": () => import("./../../../src/pages/torneo-2020.js" /* webpackChunkName: "component---src-pages-torneo-2020-js" */),
  "component---src-pages-torneo-js": () => import("./../../../src/pages/torneo.js" /* webpackChunkName: "component---src-pages-torneo-js" */),
  "component---src-pages-torneo-registro-js": () => import("./../../../src/pages/torneo-registro.js" /* webpackChunkName: "component---src-pages-torneo-registro-js" */),
  "component---src-pages-tutor-js": () => import("./../../../src/pages/tutor.js" /* webpackChunkName: "component---src-pages-tutor-js" */)
}

